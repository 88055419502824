<template>
  <div>
    <v-input v-model="form.model"
             label="Model"
             place-holder-text="Voorbeeld (3-Serie)"
             :disabled="disabled"
             :show-errors="showErrors"/>
    <v-validate-button :disabled="disabled"
                       :form="form"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import validations from "@/components/forms/model/model.validator";
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  created() {
    this.form = validations
    this.form.model.value = this.data.model
  }
}
</script>
