<template>
  <d-container fluid>
    <pageTitle title="Overzicht" subtitle="Aankoop aanmaken"/>
    <d-row>
      <vCard :col="12" header="Aankoop">
        <div class="row">
          <div class="col-md-6">
            <addPurchase @done="saved"/>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <h3>Werk indicatie</h3>
                <p style="color: #777"><span style="font-weight: bold">Letop:</span> Dit gaat om een indiciatie, de
                  werkplaats
                  gebruikt deze informatie om zo goed
                  mogelijk een inschatting van het werk te kunnen maken op een voertuig.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="">Optisch</label>
                <addExpectedDamageReport :type="$api.expectedDamageReport.OPTICAL" />
                <pre>{{ expectedOpticalDamageReport }}</pre>
              </div>
              <div class="col-md-12">
                <label for="">Technisch</label>
                <addExpectedDamageReport :type="$api.expectedDamageReport.TECHNICAL" />
              </div>
              <pre>{{ expectedOpticalDamageReport }}</pre>
              <pre>{{ expectedTechnicalDamageReport }}</pre>
              <div class="col-md-12" style="margin-top: 20px">
                <!--              <p>Totale opgegeven kosten: <span>{{ totalDamage.optical + totalDamage.technical | currency }}</span></p>-->
              </div>
            </div>
          </div>
        </div>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
import addPurchase from "@/components/forms/purchase/addPurchase";
import addExpectedDamageReport from "@/components/forms/expectedDamageReport/addExpectedDamageReport";

export default {
  name: 'Voorbeeld',
  title: 'Aankoop aanmaken',
  components: {
    addPurchase,
    addExpectedDamageReport
  },
  data() {
    return {
      expectedTechnicalDamageReport: null,
      expectedOpticalDamageReport: null,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    createExpectedDamageReport(purchase) {
      this.damages.forEach(({description, price}) => {
        if (!!description.value || price > 0) {
          this.$api.expectedDamageReport.create(
              this.type,
              description.value,
              purchase,
              price
          )
        }
      })
    },
    saved () {
      alert('shit is saved')
    }
  }
}
</script>

<style scoped>

</style>
