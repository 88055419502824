<template>
  <div>
    <div v-for="(expectedDamageReport, index) in expectedDamageReports">
      <div class="row">
        <div class="col-md-10">
          <form-expected-damage-report :data="expectedDamageReport"
                                       :disabled="true"
                                       :index="index"
                                       :show-errors="showErrors"
                                       @submit="updateExpectedDamageReport"/>
        </div>
        <div class="col-md-2">
          <button class="btn btn-primary" @click="removeExpectedDamageReport(index)">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-primary" @click="addExpectedDamageReport">
          <i class="material-icons">add</i> Toevoegen
        </button>
        <button class="btn btn-primary" @click="saveExpectedDamageReport">
          <i class="material-icons">save</i> Opslaan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import validator from './expectedDamageReport.validator'

export default {
  components: {
    formExpectedDamageReport: () => import('./formExpectedDamageReport')
  },
  created() {
    this.addExpectedDamageReport()
  },
  props: {
    type: {
      required: true,
    }
  },
  data() {
    return {
      expectedDamageReports: [],
      showErrors: false
    }
  },
  methods: {
    addExpectedDamageReport() {
      this.expectedDamageReports.push(Object.assign({}, validator))
    },
    removeExpectedDamageReport(index) {
      this.expectedDamageReports.splice(index, 1)
    },
    updateExpectedDamageReport({index, form}) {
      this.expectedDamageReports[index] = form
    },
    saveExpectedDamageReport() {
      let hasErrors = false
      this.expectedDamageReports.forEach(v => {
        this.$v.validate(v, () => {
          hasErrors = false
        }, () => {
          hasErrors = true
        })
      })
      this.showErrors = hasErrors
    }
  }
}
</script>

<style>
button {
  margin-right: 10px;
}
</style>
