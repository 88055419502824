<template>
  <div>
    <form-address @valid="createSupplierAddress" :disabled="false" ref="supplierAddress"/>
  </div>
</template>

<script>
import formAddress from './formSupplierAddress'

export default {
  name: 'addAddress',
  components: {
    formAddress
  },
  props: {
    supplier: {
      required: true
    }
  },
  data() {
    return {
      isDisabled: false
    }
  },
  methods: {
    async createSupplierAddress(form) {
      this.isDisabled = true

      try {
        const {data} = await this.$api.supplierAddress.create(
            form.postalCode, form.city, form.houseNumber, form.street, form.country.id,
            false, form?.phoneNumber, form?.contactName, this.supplier.id
        )
        this.$refs.supplierAddress.submit(data, true, 'Leverancier adres aangemaakt')
      } catch (e) {
        this.$toasted.show('Aanmaken mislukt')
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>
