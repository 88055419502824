<template>
  <div class="col-md-12" v-if="forms">
    <div class="row">
      <div class="col-md-12">
        <h3>Voertuig opvoeren</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <selectSupplierAddress @submit="setSupplierAddress" :show-errors="showErrors"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <v-input v-model="forms.licensePlate" label="Kenteken" placeholder="Voorbeeld (19-TGS-2)"
                 :show-errors="showErrors" :disabled="disabled"/>
      </div>
      <div class="col-md-2">
        <p style="text-align: center; margin-top: 25px">Of</p>
      </div>
      <div class="col-md-5">
        <v-input v-model="forms.chassisNumber" label="Chassie nummer" placeholder="Voorbeeld (SV30-0169266)"
                 :show-errors="showErrors" :disabled="disabled"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>Aankoopprijs<span style="color: red">*</span></label>
        <money v-model="forms.purchasePrice.value" class="form-control" style="margin-bottom: 10px;"/>
      </div>
      <div class="col-md-12">
        <v-select label="Aankoop type"
                  placeHolderText="Selecteer type"
                  v-model="forms.purchaseType"
                  :options="purchaseTypes"
                  options-key="id"
                  options-value="name"
                  :show-errors="showErrors"
                  :disabled="disabled"/>
      </div>
    </div>
    <button v-if="!disabled" class="btn btn-primary" @click="validate">Opslaan</button>
  </div>
</template>

<script>
import validations from "@/components/forms/purchase/purchase.validator";
import selectSupplierAddress from "@/components/forms/selectSupplierAddress";
import selectVehicleModel from "@/components/forms/selectVehicleModel";

export default {
  components: {
    selectVehicleModel,
    selectSupplierAddress
  },
  created() {
    this.setPurchaseTypes()
  },
  props: {
    data: {
      required: false,
      default: {}
    },
    disabled: {
      required: false,
      default: false
    }
  },
  mounted() {
    if (Object.keys(this.data).length === 0) {
      this.data = {}
    }
  },
  data() {
    return {
      totalDamage: {
        optical: 0,
        technical: 0,
      },
      supplierAddress: null,
      purchaseTypes: null,
      showErrors: false,
      forms: validations,
    }
  },
  watch: {
    data: {
      handler: function (_, data) {
        this.data = this.$v.inject(validations, data)
      }
    }
  },
  methods: {
    async setPurchaseTypes() {
      let {data} = await this.$api.purchaseType.list()
      this.purchaseTypes = data
    },
    setSupplierAddress(data) {
      this.supplierAddress = data
    },
    validate() {
      let {forms, supplierAddress} = this

      this.$v.validate(forms, (obj) => {
        this.$v.validate([supplierAddress], () => {
          Object.assign(obj, {supplierAddress: this.$v.extract([supplierAddress])[0]})
          this.$emit('valid', obj)
        })
      }, () => {
        this.showErrors = true
      })
    }
  }
}
</script>
