import {fixedLength, minLength, required} from "@/utils/validator";

export default {
    postalCode: {
        validations: {
            required,
            fixedLength: fixedLength(6),
        }
    },
    city: {
        validations: {
            required
        }
    },
    houseNumber: {
        validations: {
            required
        }
    },
    street: {
        validations: {
            required
        }
    },
    contactName: {
        validations: {}
    },
    phoneNumber: {
        validations: {
            minLength: minLength(10)
        }
    },
    country: {
        validations: {
            required
        }
    },
}
