<template>
  <div>
    <form-make @valid="createVehicleMake" ref="makeForm" :disabled="false"/>
  </div>
</template>

<script>
import formMake from './formMake'

export default {
  name: 'addAddress',
  components: {
    formMake
  },
  data() {
    return {
      isDisabled: false
    }
  },
  methods: {
    async createVehicleMake({make}) {
      this.isDisabled = true
      try {
        const {data} = await this.$api.make.create(make)
        this.$refs.makeForm.submit(data, true, 'Merk aangemaakt')
      } catch (e) {
        this.$toasted.show('Merk kon niet worden aangemaakt')
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>
