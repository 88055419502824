import {fixedLength, minLength, isLicensePlate, biggerThen, required} from "@/utils/validator";

export default {
    licensePlate: {
        validations: {
            required,
            isLicensePlate
        }
    },
    purchasePrice: {
        validations: {
            required,
            biggerThen: biggerThen(0),
        }
    },
    purchaseType: {
        value: null,
        validations: {
            required
        }
    },
    chassisNumber: {
        validations: {
        }
    },
    opticalDamages: {
        validations: {}
    },
    technicalDamages: {
        validations: {}
    }
}
