<template>
  <div>
    <v-select :label="!hideLabels ? `Merk` : ``"
              placeHolderText="Selecteer merk"
              v-model="make"
              :options="makes"
              optionsKey="id"
              optionsValue="name"
              :show-errors="false"
              :disabled="false">
      <template #beforeList>
        <button class="btn btn-primary" @click="createMake">
          <i class="material-icons">add</i> Voeg merk toe
        </button>
      </template>
    </v-select>
    <v-select :label="!hideLabels ? `Model` : ``"
              placeHolderText="Selecteer model"
              v-model="model"
              :options="models"
              optionsKey="id"
              optionsValue="name"
              :show-errors="false"
              :disabled="false">
      <template #beforeList>
        <button class="btn btn-primary" @click="createModel" v-if="make.value">
          <i class="material-icons">add</i> Voeg model toe
        </button>
      </template>
    </v-select>

    <d-modal v-if="modalCreateMake" @close="modalCreateMakeCloseHandler">
      <d-modal-header>
        <d-modal-title>Merk toevoegen</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <addMake @submit="modalCreateMakeAddedEvent"/>
      </d-modal-body>
    </d-modal>

    <d-modal v-if="modalCreateModel" @close="modalCreateModelCloseHandler">
      <d-modal-header>
        <d-modal-title>Model toevoegen - {{ make.value.name }}</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <addModel :make="make.value" @submit="modalCreateModelAddedEvent"/>
      </d-modal-body>
    </d-modal>
  </div>
</template>

<script>
import addMake from "@/components/forms/make/addMake";
import addModel from "@/components/forms/model/addModel";

export default {
  name: '',
  components: {
    addMake,
    addModel
  },
  props: {
    hideLabels: {
      required: false,
      default: false
    }
  },
  created() {
    this.getMakes()
  },
  data() {
    return {
      make: null,
      makes: [],
      model: null,
      models: [],
      modalCreateMake: false,
      modalCreateModel: false,
    }
  },
  watch: {
    make: {
      deep: true,
      handler(make) {
        if (make?.value?.id) {
          this.model = null
          this.getModels(make.value.id)
        }
      }
    },
    model: {
      deep: true,
      handler(e){
        if (e?.value?.id) {
          this.$emit('submit', e)
        }
      }
    }
  },
  methods: {
    async getMakes() {
      try {
        let {data} = await this.$api.make.list()
        this.makes = data
      } catch (e) {
      }
    },
    async getModels(makeId) {
      try {
        let {data} = await this.$api.model.getByMake(makeId)
        this.models = data
      } catch (e) {
      }
    },
    createMake() {
      this.modalCreateMake = true
    },
    modalCreateMakeCloseHandler() {
      this.modalCreateMake = false
    },
    createModel() {
      this.modalCreateModel = true
    },
    modalCreateModelCloseHandler() {
      this.modalCreateModel = false
    },
    modalCreateMakeAddedEvent(makeAdded) {
      this.modalCreateMake = false
      this.makes.push(makeAdded)
      this.make.value = makeAdded
    },
    modalCreateModelAddedEvent(modelAdded) {
      this.modalCreateModel = false
      this.models.push(modelAdded)
      this.model.value = modelAdded
    },
  }
}
</script>