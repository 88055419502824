<template>
  <div>
    <form-model @valid="createVehicleModel" :disabled="isDisabled" ref="model"/>
  </div>
</template>

<script>
import formModel from './formModel'

export default {
  name: 'addAddress',
  components: {
    formModel
  },
  props: {
    make: {
      required: true,
    }
  },
  data() {
    return {
      isDisabled: false
    }
  },
  methods: {
    async createVehicleModel(value) {
      this.isDisabled = true
      try {
        const {data} = await this.$api.model.create(value.model, this.make.id)
        this.$refs.model.submit(data, true, 'Model aangemaakt')
      } catch (e) {
        this.$toasted.show('Model aanmaken mislukt')
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>
