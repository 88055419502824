<template>
  <div>
    <form-purchase @valid="createVehicle" :disabled="false"/>
  </div>
</template>

<script>
import formPurchase from './formPurchase'

export default {
  name: 'addAddress',
  components: {
    formPurchase
  },
  data() {
    return {
      supplier: null
    }
  },
  methods: {
    async createVehicle(form) {
      try {
        this.$toasted.show('Aanmaken van leverancier (dit kan een paar seconden duren)')
        let vehicleResponse = await this.$api.vehicle.getByLicensePlate(form.licensePlate)

        if (vehicleResponse.data.length < 1) {
          let response = await this.$api.vehicle.create(form.licensePlate)
          await this.createPurchase(response.data[0], form)
        } else if (vehicleResponse.data.length > 1){
          //
        } else {
          await this.createPurchase(vehicleResponse.data[0], form)
        }
      } catch (e) {
        this.$toasted.show('Aanmaken mislukt')
      }
    },

    async createPurchase(vehicle, form) {
      try {
        let {data} = await this.$api.purchase.create(
            form.purchaseType.id,
            vehicle.id,
            1,
            form.supplierAddress.id,
            form.purchasePrice
        )
        this.$emit('done', data)
      } catch (e) {
      }
    }
  }
}
</script>
