<template>
  <div v-if="forms">
    <v-input v-model="forms.postalCode" label="Postcode" placeholderText="Voorbeeld (2861EW)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.city" label="Woonplaats" placeholderText="Voorbeeld (Bergambacht)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.street" label="Straat" placeholderText="Voorbeeld (Ambachtstraat)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.houseNumber" label="Huisnummer" placeholderText="Voorbeeld (11)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.contactName" label="Contact persoon" placeholderText="Voorbeeld (Klaas vaak)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.phoneNumber" label="Telefoonnummer" placeholderText="Voorbeeld (0182748576)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-select v-model="forms.country"
              placeHolderText="Kies een optie"
              :disabled="disabled"
              label="Land"
              :options="[{id: 1, name: 'Nederland'}, {id: 2, name: 'België'}]"
              options-key="id"
              options-value="name"
              :show-errors="showErrors"/>
    <v-validate-button :disabled="disabled"
                       :form="forms"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import validations from "@/components/forms/supplierAddress/supplierAddress.validator";
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  mounted() {
    if (Object.keys(this.data).length === 0) {
      this.data = {}
    }
  },
  data() {
    return {
      forms: validations,
    }
  },
  watch: {
    data: {
      handler: function (_, data) {
        this.data = this.$v.inject(validations, data)
      }
    }
  }
}
</script>
