import {required, biggerThen} from "@/utils/validator";

export default {
    description: {
        validations: {
            required
        }
    },
    price: {
        validations: {
            biggerThen: biggerThen(0)
        }
    }
}