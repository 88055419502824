<template>
  <div>
    <v-input v-model="form.supplier"
             label="Model"
             place-holder-text="Ototax B.V"
             :disabled="disabled"
             :show-errors="showErrors"/>
    <v-validate-button :disabled="disabled"
                       :form="form"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import validations from "@/components/forms/supplier/supplier.validator";
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  data() {
    return {
      form: validations,
    }
  }
}
</script>
