<template>
  <div class="row v-input">
    <div class="col-md-12">
      <v-select label="Leverancier"
                v-model="supplier"
                :options="suppliers"
                placeHolderText="Selecteer leverancier"
                optionsKey="id"
                optionsValue="name">
        <template #beforeList>
          <button class="btn btn-primary" @click="addSupplier">
            <i class="material-icons">add</i> Voeg leverancier toe
          </button>
        </template>
      </v-select>
    </div>
    <div class="col-md-12">
      <v-select label="Leverancier adres"
                v-model="supplierAddress"
                :options="supplierAddresses"
                placeHolderText="Selecteer locatie"
                optionsKey="id"
                optionsValue="name"
                :disabled="!supplier">
        <template #beforeList>
          <button class="btn btn-primary" @click="addSupplierAddress" :disabled="!supplier.value">
            <i class="material-icons">add</i> Voeg adres toe
          </button>
        </template>
      </v-select>
    </div>
    <d-modal v-if="modalCreateSupplier" @close="modalCreateSupplierCloseHandler">
      <d-modal-header>
        <d-modal-title>Leverancier</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <addSupplier @submit="supplierAddedEvent"/>
      </d-modal-body>
    </d-modal>
    <d-modal v-if="modalCreateSupplierAddress" @close="modalCreateSupplierAddressCloseHandler">
      <d-modal-header>
        <d-modal-title>Leverancier: {{ supplier.name }}</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <addSupplierAddress :supplier="supplier.value" @submit="supplierAddressAddedEvent"/>
      </d-modal-body>
    </d-modal>
  </div>
</template>

<script>
import sortBy from 'lodash.sortby'
import addSupplier from "@/components/forms/supplier/addSupplier";
import addSupplierAddress from "@/components/forms/supplierAddress/addSupplierAddress";

export default {
  components: {
    addSupplier,
    addSupplierAddress
  },
  data() {
    return {
      supplier: null,
      suppliers: [],
      supplierAddress: null,
      supplierAddresses: [],
      modalCreateSupplier: false,
      modalCreateSupplierAddress: false,
    }
  },
  created() {
    this.getSuppliers()
  },
  props: {
    showErrors: {
      required: true,
    }
  },
  watch: {
    supplier: {
      handler(newValue, _) {
        if (newValue !== _ && newValue?.value?.id) {
          this.getSupplierAddress(newValue.value.id)
          this.supplierAddress = null
        }
      }
    },
    supplierAddress: {
      handler(newValue, _) {
        this.$emit('submit', newValue)
      }
    }
  },
  methods: {
    addSupplier() {
      this.modalCreateSupplier = true
    },
    addSupplierAddress() {
      this.modalCreateSupplierAddress = true
    },
    modalCreateSupplierCloseHandler() {
      this.modalCreateSupplier = false
    },
    modalCreateSupplierAddressCloseHandler() {
      this.modalCreateSupplierAddress = false
    },
    supplierAddedEvent(supplier) {
      this.supplier = supplier
      this.getSuppliers()
      this.modalCreateSupplier = false
    },
    supplierAddressAddedEvent(supplierAddress) {
      this.getSupplierAddress(this.supplier.id).then(() => {
        this.supplierAddress.value = this.supplierAddresses.find(x => x.id === supplierAddress.id)
      })
      this.modalCreateSupplierAddress = false
    },
    async getSuppliers() {
      try {
        const {data} = await this.$api.supplier.list()
        this.suppliers = sortBy(data, o => o.name.toLowerCase())
      } catch (e) {

      }
    },
    async getSupplierAddress(id) {
      try {
        const {data} = await this.$api.supplierAddress.getBySupplier(id)

        let supplierAddress = []
        data.forEach((address) => {
          address['name'] = `${address['city']}, ${address['street']} ${address['house_number']} (${address['postal_code']})`
          supplierAddress.push(address)
        })

        this.supplierAddresses = sortBy(supplierAddress, o => o.name.toLowerCase())
      } catch (e) {
          alert('Er zijn geen adressen gevonden')
      }
    }
  }
}
</script>
