<template>
  <supplierForm ref="supplierForm" @valid="createSupplier" />
</template>

<script>
import supplierForm from './formSupplier'

export default {
  name: 'addSupplier',
  components: {supplierForm},
  data() {
    return {
      isDisabled: false,
    }
  },
  methods: {
    async createSupplier(form) {
      try {
        this.isDisabled = true
        let {data, status} = await this.$api.supplier.create(form.supplier)
        this.$refs.supplierForm.submit(data, true, 'Leverancier is succesvol aangemaakt')
      } catch (e) {

      } finally {
        this.isDisabled = false
      }
    },
  },
}
</script>
